/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useEffect, useCallback, useState, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceEventsByIdApiCall } from '../../services/devices';
import { getEventLogByIMEI, getDevices } from '../../actions/devices';
import { getTitleAsCurrentNameSerialNumber, getCurrentDevice } from '../Utils/helpers'
import { DataTable } from '../Utils/reusableTable'
import { PropagateLoader } from 'react-spinners';


export default function ViewDeviceEventsById(props) {
  const { eventLogs, totalCount } = useSelector((state) => state.devices.eventlogByIMEI);
  const devices = useSelector((state) => state.devices.devices) || [];

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [imeiNumber, setImeiNumber] = useState();
  const [downloading, setDownloading] = useState(false);
  const [isDM1, setIsDM1] = useState(undefined)
  const [fetchCount, setFetchCount] = useState(undefined)
  const [ready, setReady ] = useState(false)

  const commonColumns = [
    "PacketTimestamp", "EventID", "EventTimestamp", "IMEINumber"
  ]
  const overrideNotToDisplay = ["PacketTimestamp", "EventID", "EventTimestamp", "IMEINumber"]

  const extraDisplayColumns = [
    "date", "Ongoing_fault", "PrefaultCurrent", "FaultCurrent", "RecoverCurrent", "RecordID", "Event_Record_ID", "EventType"
  ];

  const extraDm1DisplayColumns = [
    "EventTypeStatus", "PhaseAEventStatus", "PhaseBEventStatus", "PhaseCEventStatus"
  ]

  useEffect(() => {
    try {
      setReady(false)
      const search = props.location.search;
      const params = new URLSearchParams(search);
      const id = params.get('id');
      setImeiNumber(id);
      dispatch(getEventLogByIMEI(id, page, pageSize));
      dispatch(getDevices())
    } catch (error) {
      console.error(`An error occured: ${error}`)
    }
    
  }, [page, pageSize, imeiNumber, dispatch, fetchCount]);


  const onDownloadClick = useCallback(
    async (from, to) => {
      setDownloading(true);
      const res = await getDeviceEventsByIdApiCall(imeiNumber, 0, totalCount, from, to);
      setDownloading(false);
      return res.data[1];
    },
    [totalCount, imeiNumber]);


  useEffect(() => {
    const { model }  = getCurrentDevice(devices, imeiNumber)

    if(model){
      setIsDM1(model.includes('DM1'))
    } else {
      setIsDM1(false)
    }

    setFetchCount(eventLogs?.length)
    setReady(true)  
  }, [devices, eventLogs])
  
  function showLoader(requiredData=[]){
    for (let index = 0; index < requiredData.length; index++) {
      if(requiredData[index] === undefined){
        return true
      }
    }

    return !ready
  }

  function getDisplayColumns(){
    if(isDM1 === undefined){
      return commonColumns;
    }
    if (isDM1){
      return [...commonColumns, ...extraDm1DisplayColumns];
    } else {
      return [...commonColumns, ...extraDisplayColumns];
    } 
  }


  return (
    <div>
      {
        showLoader([isDM1, eventLogs, fetchCount]) ? (<PropagateLoader className="Center" size={10} color="#000" speedMultiplier={0.7} />) :
        (
          <DataTable
            title={getTitleAsCurrentNameSerialNumber(devices, imeiNumber)}
            displayColumns={getDisplayColumns()}
            tableData={eventLogs}
            totalCount={totalCount}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            enableDownloadCSV={true}
            handleDownload={onDownloadClick}
            downloadProgress={downloading}
            csvFileName={`Device ${imeiNumber} events.csv`}
            usePaper={true}
            paginationMode='server'
            overrideNotToDisplay={overrideNotToDisplay}
          />
        )
      }
    </div>
  );
}
