/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';

import './style.css';
import { updateMultispeakProvidersApiCall } from '../../../services/multispeak';

export default function EditDeviceModal(props) {
  const [providerID, setProviderID] = useState('');
  const [providerName, setProviderName] = useState('');
  const [providerURL, setProviderURL] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [faultNotificationMethod, setFaultNotificationMethod] = useState('');
  const [groupAssociations, setGroupAssociations] = useState([]);

  useEffect(() => {
    if (props.msProvider && props.msProvider.ProviderID) {
      setProviderID(props.msProvider.ProviderID);
      setProviderName(props.msProvider.Name);
      setProviderURL(props.msProvider.EndpointURL);
      setUserName(props.msProvider.AccessUserName);
      setPassword('');
      if (props.msProvider.FaultNotificationMethod) {
        setFaultNotificationMethod({
          value: props.msProvider.FaultNotificationMethod,
          label: props.msProvider.FaultNotificationMethod,
        });
      }
      setGroupAssociations(
        props.msProvider.GroupAssociations.map((group) => {
          const adminGroup = props.adminGroups.find((gp) => gp.GroupName == group);
          return {
            value: adminGroup.GroupID,
            label: adminGroup.GroupName,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.msProvider]);

  function isValidUrl(url) {
    const urlRegex = /^(?:(?:https?):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
    return urlRegex.test(url);
  }

  function handleSubmit(event) {
    event.preventDefault();
    props.setLoading(true);

    if (!isValidUrl(providerURL)) {
      toast.warn('Please enter a valid endpoint URL');
      props.setLoading(false);
      return;
    }

    if (!faultNotificationMethod) {
      toast.warn('Please select the Fault Notification method');
      props.setLoading(false);
      return;
    }

    const msProviderData = {
      Name: providerName,
      EndpointURL: providerURL,
      AccessUserName: userName,
      FaultNotificationMethod: faultNotificationMethod.value,
      GroupAssociations: groupAssociations.map((option) => option.value).join(),
    };
    if (password.length) {
      msProviderData['AccessPassword'] = password;
    }

    updateMultispeakProvidersApiCall(msProviderData, providerID)
      .then((res) => {
        if (res.data.success) {
          toast.success('MultiSpeak Provider Modified');
        } else {
          toast.error('Error occured');
        }
        props.setLoading(false);
        props.handleProviderUpdated();
      })
      .catch((err) => {
        props.setLoading(false);
        toast.error('Error occured');
      });
  }
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit MultiSpeak Provider</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group size="lg" controlId="group">
              <Form.Label>Provider Name</Form.Label>
              <Form.Control
                className="mb-3"
                autoFocus
                type="text"
                value={providerName}
                onChange={(e) => setProviderName(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="group">
              <Form.Label>Provider Endpoint URL</Form.Label>
              <Form.Control
                className="mb-3"
                type="text"
                value={providerURL}
                onChange={(e) => setProviderURL(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="group">
              <Form.Label>Access Username</Form.Label>
              <Form.Control
                className="mb-3"
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="group">
              <Form.Label>Access Password</Form.Label>
              <Form.Control
                className="mb-3"
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="msProviderForm.faultNotificationMethod">
              <Form.Label>Fault Notification Method</Form.Label>
              <Select
                className="mb-3"
                onChange={(option) => {
                  if (option === null) {
                    setFaultNotificationMethod('');
                  } else {
                    setFaultNotificationMethod(option);
                  }
                }}
                value={faultNotificationMethod}
                options={[
                  {
                    value: 'AssessmentLocationChangedNotification',
                    label: 'AssessmentLocationChangedNotification',
                  },
                  {
                    value: 'ODEventNotification',
                    label: 'ODEventNotification',
                  },
                  {
                    value: 'SCADAStatusChangedNotification',
                    label: 'SCADAStatusChangedNotification',
                  },
                ]}
              />
            </Form.Group>
            <Form.Group controlId="msProviderForm.groupAssociations">
              <Form.Label>Group Associations</Form.Label>
              <Select
                className="mb-3"
                multiple
                onChange={(selectedOptions) => {
                  if (selectedOptions === null) {
                    setGroupAssociations([]);
                  } else {
                    setGroupAssociations(selectedOptions);
                  }
                }}
                options={props.adminGroups.map((adminGroup) => ({
                  value: adminGroup.GroupID,
                  label: adminGroup.GroupName,
                }))}
                isMulti={true}
                value={groupAssociations}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button
            size="sm"
            variant="secondary"
            onClick={props.handleClose}
            className="float-left"
            disabled={props.loading}
          >
            Close
          </Button>
          <Button
            className="float-right"
            size="sm"
            type="submit"
            disabled={!providerName.length || props.loading}
            onClick={handleSubmit}
          >
            {props.loading && <BeatLoader size={10} color="#fff" speedMultiplier={0.7} />}
            {!props.loading && 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
