import API from './axios';

export const addOrganizationApiCall = async (data) => {
  return API.post('api/admin/organization', data);
};

export const getOrganizationsGroupsApiCall = async () => {
  return API.get('api/admin/organization-groups');
};

export const updateOrganizationsApiCall = async (msProvider, OrganizationID) => {
  return API.patch('api/admin/organization', msProvider, {
    params: {
      OrganizationID,
    },
  });
};

export const deleteOrganizationApiCall = async (OrganizationID) => {
  return API.delete('api/admin/organization', {
    params: {
      OrganizationID,
    },
  });
};
