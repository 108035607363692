import axios from 'axios';

const _ = require('dotenv').config();

const API_ADDRESS = process.env.REACT_APP_API_ADDRESS
  ? process.env.REACT_APP_API_ADDRESS
  : 'https://wireless-api.herokuapp.com';

console.log('REACT_APP_API_ADDRESS=' + API_ADDRESS);
export default axios.create({
  // baseURL: `http://3.23.103.16:8080/`,
  //baseURL: `http://localhost:8080/`,
  // baseURL: `https://wireless-api.herokuapp.com/`,
  baseURL: API_ADDRESS,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});
