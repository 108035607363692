import React from 'react';
import HashLoader from 'react-spinners/HashLoader';

export default function FullLoader() {
  return (
    <div className="loader-background">
      <div>
        <HashLoader width={150} color={'#000'} />
      </div>
    </div>
  );
}
