/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Modal, Form, ProgressBar } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import { changePasswordApiCall } from '../../services/users';

const getPasswordStrength = (password) => {
  let strength = 0;
  if (password.length >= 1) strength += 1;
  if (password.length >= 8) strength += 1;
  if (/[A-Z]/.test(password)) strength += 1;
  if (/[0-9]/.test(password)) strength += 1;
  if (/[^A-Za-z0-9]/.test(password)) strength += 1;
  return strength;
};

const getStrengthLabel = (strength) => {
  switch (strength) {
    case 0:
    case 1:
      return 'Weak';
    case 2:
    case 3:
      return 'Moderate';
    case 4:
    case 5:
      return 'Strong';
    default:
      return '';
  }
};

export function PasswordChangeModal({
  showPasswordModal,
  handleClosePasswordModal,
  showConfirmation,
  setShowConfirmation,
}) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);

  const [loading, setLoading] = useState(false);

  const handleSubmitPassword = () => {
    if (oldPassword.length == 0) {
      toast.warn(`Please enter your old password.`);
      return;
    }
    if (newPassword.length == 0) {
      toast.warn(`Please enter your new password.`);
      return;
    }
    if (passwordStrength < 3) {
      toast.warn(
        `New password is too weak. Use a combination of upper/lower case letters, numbers and symbols.`,
      );
      return;
    }
    if (newPassword != newPasswordAgain) {
      toast.warn(`New passwords don't match.`);
      return;
    }
    setShowConfirmation(true);
    handleClosePasswordModal();
  };

  const handleConfirmPassword = () => {
    setLoading(true);
    changePasswordApiCall({
      oldPassword,
      newPassword,
    })
      .then((apiRes) => {
        if (apiRes.data.success) {
          toast.success('Password changed successfully');
          clearForm();
        } else {
          toast.error('Password change failed. Please contact the administrator.');
        }
        setLoading(false);
        setShowConfirmation(false);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          toast.error('You have provided an incorrect password.');
        } else {
          toast.error(err.response.data.message);
        }
        setLoading(false);
        setShowConfirmation(false);
      });
  };

  function clearForm() {
    setOldPassword('');
    setNewPassword('');
    setNewPasswordAgain('');
    setPasswordStrength(0);
  }

  return (
    <>
      <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group size="lg" controlId="password">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="password">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setPasswordStrength(getPasswordStrength(e.target.value));
                }}
                isValid={newPassword && newPassword === newPasswordAgain}
              />
              <ProgressBar
                now={(passwordStrength / 5) * 100}
                variant={
                  passwordStrength <= 1 ? 'danger' : passwordStrength === 2 ? 'warning' : 'success'
                }
                label={getStrengthLabel(passwordStrength)}
                className="mt-2"
              />
            </Form.Group>
            <Form.Group size="lg" controlId="password">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                value={newPasswordAgain}
                onChange={(e) => setNewPasswordAgain(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleClosePasswordModal}>
              Close
            </Button>
            <Button
              className="float-right"
              size="sm"
              variant="primary"
              onClick={handleSubmitPassword}
            >
              Change Password
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Password Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to change your password?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="float-right"
            size="sm"
            onClick={handleConfirmPassword}
            disabled={loading}
          >
            {loading && <BeatLoader size={10} color="#fff" speedMultiplier={0.7} />}
            {!loading && 'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
