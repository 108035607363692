
export function getTitleAsCurrentNameSerialNumber(devices, imeiNumber){
  const { Real_Name, FCIUniqueSerialNumber } = getCurrentDevice(devices, imeiNumber)
  return `${Real_Name || ''} | ${FCIUniqueSerialNumber || ''}`
}


export function getCurrentDevice(devices, imeiNumber){
  let device = {};
  
  for (let index = 0; index < devices.length; index++) {
    const { IMEINumber } = devices[index]
    if (imeiNumber && (IMEINumber === imeiNumber)){
      device= devices[index]
    }
  }
  return device
}