/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { HashLoader } from 'react-spinners';
import LinearProgress from '@mui/material/LinearProgress';

import logo from './logo.png';
import './style.css';
import { resetPasswordApiCall } from '../../services/users';
import history from '../../lib/history';

const getPasswordStrength = (password) => {
  let strength = 0;
  if (password.length >= 1) strength += 1;
  if (password.length >= 8) strength += 1;
  if (/[A-Z]/.test(password)) strength += 1;
  if (/[0-9]/.test(password)) strength += 1;
  if (/[^A-Za-z0-9]/.test(password)) strength += 1;
  return strength;
};

export default function ResetPassword(props) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resetToken, setResetToken] = useState('');

  useEffect(() => {
    const token = props.match.params.token;
    setResetToken(token);
  }, []);

  function handleSubmit(event) {
    if (passwordStrength < 3) {
      toast.warn(
        `New password is too weak. Use a combination of upper/lower case letters, numbers and symbols.`,
      );
      return;
    }
    if (password != confirmPassword) {
      toast.warn(`New passwords don't match.`);
      return;
    }
    setLoading(true);
    resetPasswordApiCall(resetToken, {
      password,
      confirmPassword,
    })
      .then((apiRes) => {
        if (apiRes.data.success) {
          toast.success('Your password reset was successful! Redirecting to the login page');
          setTimeout(() => history.push('/auth'), 4000);
        } else {
          toast.error('Something went wrong, please try again later.');
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Something went wrong, please try again later.');
        }
        setLoading(false);
      });
  }

  return (
    <div className="limiter">
      <ToastContainer position="top-right" />
      <div className="container-login100">
        <div className="wrap-login100">
          <div>
            <img src={logo} alt="Logo" className="Center Logo" />
            <h5 className="Center Margin mb-4">Reset Password</h5>
            <div className="login-fields-container">
              <TextField
                id="outlined-password-input"
                label="New Password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordStrength(getPasswordStrength(e.target.value));
                }}
                className="full-width"
              />
              <LinearProgress
                variant="determinate"
                value={(passwordStrength / 5) * 100}
                className="login-fields"
              />
              <TextField
                id="outlined-confirm-password-input"
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="login-fields"
              />
            </div>
            <Button
              size="medium"
              type="submit"
              variant="contained"
              className="full-width"
              disabled={loading || !(password.length && confirmPassword.length)}
              onClick={handleSubmit}
            >
              {loading && <HashLoader size={30} color="#1b6bae" speedMultiplier={0.8} />}
              {!loading && 'Submit'}
            </Button>
            <div className="mt-2 auth-nav-link">
              <Link to="/auth">Return to Sign In</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
