import API from './axios';

export const addMultispeakProviderApiCall = async (data) => {
  return API.post('api/multispeak/ms-provider', data);
};

export const getMultispeakProvidersGroupsApiCall = async () => {
  return API.get('api/multispeak/ms-provider-groups');
};

export const updateMultispeakProvidersApiCall = async (msProvider, ProviderID) => {
  return API.patch('api/multispeak/ms-provider', msProvider, {
    params: {
      ProviderID,
    },
  });
};

export const deleteMultispeakProviderApiCall = async (ProviderID) => {
  return API.delete('api/multispeak/ms-provider', {
    params: {
      ProviderID,
    },
  });
};
