import { SET_USER } from '../constants/action-types';
const initalUserState = {
  user: {},
  error: null,
};

const reducer = (state = initalUserState, action) => {
  switch (action.type) {
    case SET_USER: {
      return { ...state, user: action.payload };
    }
    default: {
      return state;
    }
  }
};
export { reducer as UserReducer };
