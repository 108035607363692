import { TOGGLE_ADMIN_SUB_MENU } from '../constants/action-types';
const initalUiState = {
  showAdminSubMenu: false,
};

const reducer = (state = initalUiState, action) => {
  switch (action.type) {
    case TOGGLE_ADMIN_SUB_MENU: {
      return { ...state, showAdminSubMenu: !state.showAdminSubMenu };
    }
    default: {
      return state;
    }
  }
};
export { reducer as UiReducer };
