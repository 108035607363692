/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { getDataLogCol } from '../../actions/devices';
import { getDeviceDatalogApiCall } from '../../services/devices';
import { DataTable } from '../Utils/reusableTable';
import { PropagateLoader } from 'react-spinners';

export default function ViewDevicesDataLog() {
  
  const dataLogs = useSelector((state) => state.devices.datalog);
  const dataLogsCount = useSelector((state) => state.devices.dataLogCount);
  
  const [downloading, setDownloading] = useState(false);

  const [currentPage, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);

  const [ready, setReady] = useState(undefined)
  const abortControllerRef = useRef(new AbortController());
  
  const displayColumns = [
    "Real_Name" ,"FCIUniqueSerialNumber", "date",  "Ongoing_fault", "PreviousAverageCurrent1m",
    "PreviousAverageCurrent1h","PreviousPeakCurrent24h","BatteryMCUStatus", "BatteryLEDStatus",
    "LastCheckInTime", "Lat", "Long",  "gps_long", "gps_lat","IndexID" ,"RecordID",
  ]

  useEffect(() => {
    const controller = abortControllerRef.current;
    return () => {
      controller.abort();
    };
  }, []);


  const dispatch = useDispatch();

  useEffect(() => {
    setReady(false)
    dispatch(
      getDataLogCol(
        abortControllerRef.current.signal,
        true,
        currentPage,
        pageSize
      )
    );
    if(dataLogs && dataLogs.length){
      setReady(true)
    }
  }, [dispatch, currentPage, pageSize]);

  useEffect(()=>{
    setReady(true)
  }, [dataLogs, dataLogsCount])

  const onDownloadClick = async (from, to) => {
    setDownloading(true);
    const { data } = await getDeviceDatalogApiCall(0,0,abortControllerRef.current.signal,false,from,to);
    setDownloading(false);
    const downloadedData = []
    for (let index = 0; index < data.length; index++) {
      const fullRow = data[index];
      const filteredRow = {}
      for (let index = 0; index < displayColumns.length; index++) {
        const colName = displayColumns[index];
        filteredRow[colName] = fullRow[colName] || ""
      }
      downloadedData.push(filteredRow)
    }
    return downloadedData
  };

  
  function showLoader(requiredData=[]){
    for (let index = 0; index < requiredData.length; index++) {
      if(requiredData[index] === undefined){
        return true
      }
    }

    return !ready
  }

  return (
    <div>
      {
      showLoader([dataLogs]) ? (<PropagateLoader className="Center" size={10} color="#000" speedMultiplier={0.7} />) :
      (<DataTable
        displayColumns={displayColumns}
        tableData={dataLogs}
        totalCount={dataLogsCount}
        page={currentPage}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        enableDownloadCSV={true}
        handleDownload={onDownloadClick}
        downloading={downloading}
        csvFileName={`data-log.csv`}
        usePaper={true}
        paginationMode='server'
      />)
}
    </div>
  );
}
