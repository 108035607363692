/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from '@mui/material/Button';
import Badge from 'react-bootstrap/Badge';
import { toast } from 'react-toastify';
import { ScaleLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlus } from 'react-icons/fa6';

import { EditOrganizationModal, NewOrganizationModal } from './modal';
import { toggleAdminSubMenu } from '../../../actions/ui';
import { DeleteModal } from '../../Modal/DeleteModal';
import {
  deleteOrganizationApiCall,
  getOrganizationsGroupsApiCall,
} from '../../../services/organizations';

export default function Organizations() {
  const showAdminSubMenu = useSelector((state) => state.ui.showAdminSubMenu);

  const [adminGroups, setAdminGroups] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showNewOrganizationModal, setShowNewOrganizationModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState();
  const [loading, setLoading] = useState(false);
  const [pageReady, setPageReady] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!showAdminSubMenu) {
      dispatch(toggleAdminSubMenu());
    }
  }, [dispatch]);

  useEffect(() => {
    getOrganizations().then(() => {
      setPageReady(true);
    });
  }, []);

  async function getOrganizations() {
    await getOrganizationsGroupsApiCall().then((res) => {
      if (res.data.length > 1) {
        setAdminGroups(res.data[0]);
        setOrganizations(lookupAdminGroups(res.data[1], res.data[0]));
      }
    });
  }

  function lookupAdminGroups(Organizations, groups) {
    Organizations.map((Organization) => {
      if (Organization.DefaultGroups) {
        let organizationGroups = [];
        organizationGroups = Organization.DefaultGroups.split(',').map((num) => {
          let adminGroup = groups.find((group) => group.GroupID == num);
          return adminGroup ? adminGroup.GroupName : null;
        });
        Organization.DefaultGroups = organizationGroups;
      } else {
        Organization.DefaultGroups = [];
      }
    });
    return Organizations;
  }

  const handleShowEditModal = (Organization) => {
    setShowEditModal(true);
    setCurrentOrganization(Organization);
  };

  const handleCloseEditModal = () => {
    if (!loading) {
      setShowEditModal(false);
    }
  };

  const handleShowNewOrgModal = () => {
    if (!loading) {
      setShowNewOrganizationModal(true);
    }
  };

  const handleCloseNewOrgModal = () => {
    if (!loading) {
      setShowNewOrganizationModal(false);
    }
  };

  const handleOrganizationUpdated = () => {
    if (!loading) {
      getOrganizations();
      setShowEditModal(false);
    }
  };

  const handleOrganizationAdded = () => {
    if (!loading) {
      getOrganizations();
      setShowNewOrganizationModal(false);
    }
  };

  const handleShowDelete = (Organization) => {
    setShowDelete(true);
    setCurrentOrganization(Organization);
  };

  const onCancelDeleteBtn = () => {
    if (!loading) {
      setShowDelete(false);
    }
  };

  const onAcceptDeleteBtn = async (OrganizationID) => {
    setLoading(true);
    deleteOrganizationApiCall(OrganizationID)
      .then((res) => {
        if (res.data.success) {
          toast.success('Organization Deleted');
        } else {
          toast.error('Error occured');
        }
        getOrganizations();
        setLoading(false);
        setShowDelete(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Error occured');
        setShowDelete(false);
      });
  };

  return (
    <div>
      {pageReady ? (
        <>
          <Button
            size="small"
            variant="contained"
            className="mt-2 float-right"
            startIcon={<FaPlus />}
            onClick={handleShowNewOrgModal}
          >
            New Organization
          </Button>
          <Table responsive size="md" className="mt-2">
            <thead>
              <tr>
                <th>Name</th>
                <th>Domain</th>
                <th>Default Groups</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {organizations.map((Organization) => (
                <tr key={Organization.OrganizationID}>
                  <td>{Organization.Name}</td>
                  <td>{Organization.Domain}</td>
                  <td>
                    {Organization.DefaultGroups.map((group) => {
                      return (
                        <span key={Organization.OrganizationID + group}>
                          <Badge bg="light">{group}</Badge>
                          &nbsp;
                        </span>
                      );
                    })}
                  </td>
                  <td>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => handleShowEditModal(Organization)}
                    >
                      Edit
                    </Button>
                  </td>
                  <td>
                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={() => handleShowDelete(Organization)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <ScaleLoader className="page-loader" />
      )}
      <EditOrganizationModal
        show={showEditModal}
        loading={loading}
        setLoading={setLoading}
        setShow={setShowEditModal}
        handleClose={handleCloseEditModal}
        handleShow={handleShowEditModal}
        Organization={currentOrganization}
        handleOrganizationUpdated={handleOrganizationUpdated}
        adminGroups={adminGroups}
      />
      <NewOrganizationModal
        show={showNewOrganizationModal}
        loading={loading}
        setLoading={setLoading}
        setShow={setShowNewOrganizationModal}
        handleClose={handleCloseNewOrgModal}
        handleShow={handleShowNewOrgModal}
        handleOrganizationAdded={handleOrganizationAdded}
        adminGroups={adminGroups}
      />
      <DeleteModal
        show={showDelete}
        name={`${currentOrganization?.Name} Organization`}
        onClickNo={onCancelDeleteBtn}
        onClickYes={onAcceptDeleteBtn}
        id={currentOrganization?.OrganizationID}
        loading={loading}
      />
    </div>
  );
}
