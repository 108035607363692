/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import './style.css';
import React, { useEffect, useState } from 'react';
import { editGroupApiCall } from '../../../services/users';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminGroups } from '../../../actions/adminActions';

const initialFields = {
  name: '',
  users: [''],
};
export default function EditGroupModal({ show, group, handleClose }) {
  const adminUsers = useSelector((state) => state.admin.users) || [];
  const [fields, setFields] = useState(initialFields);
  const [loading, setLoading] = useState(false);
  const [editGroupSuccess, setEditGroupSuccess] = useState(false);
  const [editGroupFailure, setEditGroupFailure] = useState(false);
  const dispatch = useDispatch();
  function validateForm() {
    return fields.name.length > 0;
  }
  useEffect(() => {
    if (!group) {
      return;
    }
    setFields({
      name: group.GroupName,
      users: group.users.length ? group.users.map((user) => user.UserID) : initialFields.users,
    });
  }, [group]);
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const formData = {
      ...fields,
      users: fields.users[0] ? fields.users : [],
    };
    const groupData = {
      GroupName: formData.name,
      GroupUsers: formData.users,
    };

    editGroupApiCall(groupData, group.GroupID)
      .then((res) => {
        dispatch(getAdminGroups());
        setLoading(false);
        setEditGroupSuccess(true);
        setTimeout(() => {
          setEditGroupSuccess(false);
        }, 5000);
      })
      .catch((err) => {
        setEditGroupFailure(true);
        setTimeout(() => {
          setEditGroupFailure(false);
        }, 5000);
      });
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form onSubmit={handleSubmit}>
              {editGroupSuccess && (
                <p className="text-success">
                  <strong>Group Updated</strong>
                </p>
              )}
              {editGroupFailure && (
                <p className="text-danger">
                  <strong>Some Error Occurred</strong>
                </p>
              )}
              <Form.Group size="lg" controlId="group">
                <Form.Label>Group Name</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={fields.name}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Label>Users</Form.Label>
              {fields.users.map((field, index) => (
                <Form.Control
                  className="mb-2"
                  key={'user-' + index}
                  as="select"
                  value={fields.users[index]}
                  onChange={(e) => {
                    const users = [...fields.users];
                    if (!e.target.value && users.length > 1) {
                      users.splice(index, 1);
                    } else {
                      users[index] = e.target.value;
                    }
                    setFields({
                      ...fields,
                      users,
                    });
                  }}
                >
                  <option value="">Select an Option</option>
                  {adminUsers.map((adminUser, index) => (
                    <option
                      key={'option-' + index}
                      value={adminUser.UserID}
                      disabled={!!fields.users.includes(adminUser.UserID)}
                    >
                      {adminUser.UserNameLong}
                    </option>
                  ))}
                </Form.Control>
              ))}
              <button
                type="button"
                className="btn btn-sm btn-link"
                onClick={() =>
                  setFields({
                    ...fields,
                    users: [...fields.users, ''],
                  })
                }
                disabled={!fields.users[fields.users.length - 1]}
              >
                + Add user
              </button>
              <Button
                className="mt-4"
                block
                size="lg"
                type="submit"
                disabled={!validateForm() || loading}
              >
                {loading && 'Updating Group'}
                {!loading && 'Submit'}
              </Button>
            </Form>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
