/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminDevices, getAdminGroups } from '../../../actions/adminActions';
import EditDeviceModal from './modal';
import { toggleAdminSubMenu } from '../../../actions/ui';
import { DeleteModal } from '../../Modal/DeleteModal';
import { deleteDeviceApiCall } from '../../../services/users';

export default function EditDevice() {
  const adminDevices = useSelector((state) => state.admin.devices) || [];
  const showAdminSubMenu = useSelector((state) => state.ui.showAdminSubMenu);
  const globalState = useSelector((state) => state);
  const user = globalState.user.user;
  const accessLevel = user.AccessLevel;
  const [show, setShow] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteFailure, setDeleteFailure] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentDevice, setCurrentDevice] = useState();
  const handleClose = () => setShow(false);
  const handleDeviceUpdated = () => {
    setShow(false);
    dispatch(getAdminDevices());
  };
  const handleShow = (device) => {
    setShow(true);
    setCurrentDevice(device);
  };
  const handleShowDelete = (device) => {
    setShowDelete(true);
    setCurrentDevice(device);
  };
  const onCancelDeleteBtn = () => {
    setShowDelete(false);
    setDeleteSuccess(false);
    setDeleteFailure(false);
  };
  const onAcceptDeleteBtn = async (IndexID) => {
    try {
      await deleteDeviceApiCall(IndexID);
      setDeleteSuccess(true);
      dispatch(getAdminGroups());
      dispatch(getAdminDevices());
    } catch (error) {
      setDeleteFailure(true);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminGroups());
    dispatch(getAdminDevices());
    if (!showAdminSubMenu) {
      dispatch(toggleAdminSubMenu());
    }
  }, [dispatch]);
  return (
    <div>
      <Table responsive="md">
        <thead>
          <tr>
            <th>IndexID</th>
            <th>IMEI Number</th>
            <th>Status</th>
            <th>FCI Unique Serial Number</th>
            <th>Real Name</th>
            <th>Group Association</th>
            <th>Lat</th>
            <th>Long</th>
            <th>Timezone</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {adminDevices.map((device) => (
            <tr key={device.IndexID}>
              <td>{device.IndexID}</td>
              <td>{device.IMEINumber}</td>
              <td>{device.Status}</td>
              <td>{device.FCIUniqueSerialNumber}</td>
              <td>{device.Real_Name}</td>
              <td>{device.Group_Association}</td>
              <td>{device.Lat}</td>
              <td>{device.Long}</td>
              <td>{device.Timezone}</td>
              <td>
                <Button variant="primary" onClick={() => handleShow(device)}>
                  Edit
                </Button>
              </td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => handleShowDelete(device)}
                  disabled={accessLevel !== 1}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <EditDeviceModal
        accessLevel={accessLevel}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
        device={currentDevice}
        handleDeviceUpdated={handleDeviceUpdated}
      />
      <DeleteModal
        show={showDelete}
        name="Device"
        deleteSuccess={deleteSuccess}
        onClickNo={onCancelDeleteBtn}
        onClickYes={onAcceptDeleteBtn}
        deleteFailure={deleteFailure}
        id={currentDevice?.IndexID}
      />
    </div>
  );
}
