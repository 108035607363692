import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import Header from './Header';
import Sidebar from './Sidebar';
import './style.scss';
import 'react-toastify/dist/ReactToastify.css';
const Layout = ({ children }) => {
  return (
    <div className="d-flex" id="top-page">
      <ToastContainer position="top-right" />
      <Sidebar />
      <div id="page-content-wrapper">
        <Header />

        <div className="container-fluid">{children}</div>
      </div>
    </div>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Layout;
