/* eslint-disable react/jsx-key */
import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import DownloadCSVHeaderRow from './DownloadCSVHeaderRow';
import { convertColumnToRealName } from '../../lib/convertColumnsToRealLanguage';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { PropagateLoader } from 'react-spinners';

export const DataTable =  ({
    displayColumns,
    tableData,
    totalCount,
    page,
    pageSize,
    setPage,
    setPageSize,
    enableDownloadCSV,
    handleDownload,
    downloadProgress,
    title,
    buttonColumns=[],
    buttonsData=[],
    useFieldsForTitle=[],
    csvFileName='data.csv',
    usePaper=false,
    paginationMode="server",
    overrideNotToDisplay=[]
  }) => {

  const [rows, setRows] = useState(undefined);
  const [downloading, setDownloading] = useState(false)
  const [ready, setReady] = useState(false);
  const [columnProps, setColumnProps] = useState(undefined)
  const [tableBody, setTableBody] = useState(undefined)

  const allCols = [...displayColumns, ...buttonColumns]

  function convertDateTimeFields(date){
    const dateOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }
    return new Date(date).toLocaleString("en-US", dateOptions)
  }

  const getDataToDisplay =  () => {
    if(tableData && tableData.length){
      const dataToDisplay = []
      for (let index = 0; index < tableData.length; index++) {
        const row = tableData[index];
        const filteredRow = {}
        for (let index = 0; index < displayColumns.length; index++) {
          
          const columnName = displayColumns[index];
          if(columnName.toLowerCase().includes("time") || columnName.toLowerCase().includes("date") ){
            filteredRow[columnName] = convertDateTimeFields(row[columnName]);
          } else {
            filteredRow[columnName] = (row[columnName] !== null && row[columnName] !== undefined) ? row[columnName] : "";
          } 
        }
        dataToDisplay.push(filteredRow) 
      }
      setRows(dataToDisplay)
    }
  }

  function makeDataGridPropColumns(){
    const columns = []
    // const hiddenColumns = ['IMEINumber']
    for (let index = 0; index < displayColumns.length; index++) {
      const colName = displayColumns[index]
      if(!overrideNotToDisplay.includes(colName)){
        const columnRealName = convertColumnToRealName(colName);
        let columnSize = columnRealName.length * 10;
        if (
          columnRealName.toLowerCase().includes('time') &&
          !columnRealName.toLowerCase().includes('timer')
        ) {
          columnSize = 270;
        }
        if (columnRealName.toLowerCase().includes('imei')) {
          columnSize = 170;
        }
        if (columnRealName.toLowerCase().includes('name')) {
          columnSize = 170;
        }

        columns.push({
          headerName: columnRealName,
          field: displayColumns[index],
          width: columnSize < 150 ? 150 : columnSize,
        })

      }
    }

    setColumnProps(columns)
    return;
  }

  useEffect(() => {
    try {
      getDataToDisplay();
      makeDataGridPropColumns();
    } catch (error) {
      console.error('Failed to fetch data:', error); 
    }
    
  }, [tableData, displayColumns])

  useEffect(()=>{
    if(!usePaper){
      constructTableBody()
    } else {
      setTableBody([<tr/>])
    }
    
  }, [rows, columnProps])

  useEffect(()=> {
    setReady(true)
  }, [tableBody])

  const onDownloadClick = () => {
    // This will only download current view depending on Pagesize
    setDownloading(true)
    const downloadData = rows.map((d) => {
      delete d['GeoCoords'];
      return d;
    });
    setDownloading(false)
    return downloadData
  }

  function getTitle(){
    if(useFieldsForTitle.length && tableData.length){
      const firstRow = tableData[0]
      const titleValues = []
      
      for (let index = 0; index < useFieldsForTitle.length; index++) {
        const columnName = useFieldsForTitle[index];
        titleValues.push(firstRow[columnName])
      }
      return titleValues.join(' | ')
    }
    return ''
  }

  function showLoader(requiredData=[]){
    for (let index = 0; index < requiredData.length; index++) {
      if(requiredData[index] === undefined){
        return true
      }
    }

    return !ready
  }

  
  if (totalCount === 0){
    return (
      <div className="Center">No data available</div>
    )
  }

   function constructTableBody(){
    if(rows && rows.length){
      const tableBody = new Array(rows.length)
      for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
        const row = rows[rowIdx];

        const cells = new Array(allCols.length)
        
        for (let colIdx = 0; colIdx < allCols.length; colIdx++) {
          const key = allCols[colIdx];

          if (buttonsData.hasOwnProperty(key)) {
            const button = buttonsData[key]
            const routePath = button.routePath + tableData[rowIdx][button.paramsKey]

            if(button.action === 'click'){
              cells[colIdx] = (
                <td key={colIdx} className='align-middle'>
                  <Button size="sm" variant={button.variant} className="device-row-button" onClick={()=> button.handleClick(tableData[rowidx])}>
                    {button.name}
                  </Button>
                </td>
              );
        
            } else {
              cells[colIdx] = (
                <td key={colIdx} className='align-middle'>
                  <NavLink to={routePath} className="d-flex justify-content-center">
                    <Button size="sm" variant={button.variant} className="device-row-button">
                      {button.name}
                    </Button>
                  </NavLink>
                </td>
              )
            }
          } else {
            cells[colIdx] = (<td key={colIdx} className='align-middle'>{row[key]}</td>)
          }                      
        }

        tableBody[rowIdx] = <tr key={rowIdx}>{cells}</tr>
        
      }
      setTableBody(tableBody)
    }
  }

  const getRowId = (row) => {
    const { IndexID, date, IMEINumber, RecordID, PacketTimestamp, EventTimestamp, Event_Record_ID } = row

    if(RecordID && Event_Record_ID){
      return `${RecordID}+${Event_Record_ID}`;
    }
    else if (date && IndexID) {
      return IndexID + date;
    }
    else if (date && IMEINumber){
      return IMEINumber + date;
    }
    else if (date && RecordID){
      return RecordID + date;
    }
    else if ( EventTimestamp && IMEINumber) {
      return IMEINumber + EventTimestamp;
    } 
    else if (PacketTimestamp && IMEINumber){
      return IMEINumber + PacketTimestamp;
    }
    else 
    {
      return Math.random().toString(16).slice(2);  
    } 
  }

  return (
    <>
    
    {
    showLoader([rows, columnProps, tableBody]) ? (<PropagateLoader className="Center" size={10} color="#000" speedMultiplier={0.7} />) :
    (
      <div>
      { 
      enableDownloadCSV ? 
      <DownloadCSVHeaderRow
        data={ handleDownload ? handleDownload: onDownloadClick}
        filename={csvFileName}
        loading={handleDownload? downloadProgress: downloading}
        showFilter={true}
      />: ''}

      {(()=> {
        if((useFieldsForTitle || title ) && tableData){
          return (
          <Typography align="left" variant="h5" >
            {
            (() => { 
              if(title) {
                return title
              }
              return getTitle()
            })()
            }
          </Typography>)
        }
      })()}

      <hr/>

      {(() => {
        if (usePaper) {
          return (
            
            <Paper sx={{ height: '100%' }} elevation={3}>
              
              { rows?.length ? (
                <DataGrid
                  rows={rows}
                  columns={columnProps}
                  rowCount={totalCount}
                  paginationModel={{ page: page, pageSize: pageSize }}
                  paginationMode={paginationMode}
                  onPaginationModelChange={(newPageModel) => {
                    setPage(newPageModel.page);
                    setPageSize(newPageModel.pageSize);
                  }}
                  loading={!ready}
                  getRowId={getRowId}
                  initialState={{ pagination: { page: 0, pageSize: pageSize } }}
                  pageSizeOptions={[5, 10, 15, 30, 45, 60]}
                  density={'comfortable'}
                  autoPageSize={false}
                  rowSelection={false}
                  showColumnVerticalBorder={true}
                  sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 'bold',
                      backgroundColor: '#f5f5f5 !important',
                      fontWeight: 'bold !important',
                      fontSize: '16px',
                      padding: '8px',
                    },
                    '.MuiDataGrid-root': {
                      border: 'none !important',
                      borderRadius: '20px !important',
                    },
                    '.MuiDataGrid-footerContainer': {
                      backgroundColor: '#f5f5f5 !important',
                    },
                    '.MuiDataGrid-toolbarContainer ': {
                      backgroundColor: '#f5f5f5 !important',
                      padding: '8px',
                    },
                    '.MuiTablePagination-displayedRows': {
                      marginTop: '1em',
                      marginBottom: '1em',
                    },
                    '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                      marginTop: '1em',
                      marginBottom: '1em',
                    },
                  }}
                />
              ) : (
                <div className="Center">No data available</div>
              )}
            </Paper>
          );
        }
        return (
          <Table responsive="md">
              <thead>
                <tr>
                  {
                    (() => {
                      const headers = [];
                      for (let i = 0; i < allCols.length; i++) {
                        const colName = convertColumnToRealName(allCols[i])
                        headers.push(<th key={allCols[i]} className='align-middle'>{colName}</th>);
                      }
                      return headers;
                    })()
                  }
                </tr>
              </thead>

              <tbody>
                {
                  tableBody
                }
              </tbody>
          </Table>
          );
      })()}
    </div>
    )}
    </>
  )
}

DataTable.propTypes = {
  displayColumns: PropTypes.array,
  tableData: PropTypes.array,
  buttonColumns: PropTypes.array,
  buttonsData: PropTypes.any,
  enableDownloadCSV: PropTypes.bool,
  csvFileName: PropTypes.string,
  usePaper: PropTypes.bool,
  totalCount: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  setPage: PropTypes.func,
  setPageSize: PropTypes.func,
  paginationMode: PropTypes.string,
  handleDownload: PropTypes.func,
  downloadProgress: PropTypes.bool,
  useFieldsForTitle: PropTypes.array,
  title: PropTypes.string,
  overrideNotToDisplay: PropTypes.array
}
