/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFaultEventLog } from '../../actions/devices';

import './style.css';
import { getCriticalDeviceDataApiCall } from '../../services/devices';

import { DataTable } from '../Utils/reusableTable';
import { PropagateLoader } from 'react-spinners';

export default function ViewFaultEventLog() {
  const faultEventLogs = useSelector((state) => state.devices.criticalData) || [];

  const dispatch = useDispatch();

  const [totalCount, setTotalCount] = useState(undefined);
  const [downloading, setDownloading] = useState(false);
  const [ready, setReady] = useState(false);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);

  const abortControllerRef = useRef(new AbortController());

  const faultEventLogColumns = [
    "Real_Name", "date", "Ongoing_fault", "Extended_Ongoing_fault", "PrefaultCurrent", "FaultCurrent", "RecoverCurrent",
    "EventType","Event_Record_ID", "EventID", "RecordID", "IMEINumber", "IndexID"
  ] 
  const overrideNotToDisplay = ["EventID", "IMEINumber", "IndexID", "Extended_Ongoing_fault"]

  useEffect(() => {
    if(faultEventLogs.length){
      const { TotalCount } = faultEventLogs[0]
      setTotalCount(Number(TotalCount))
      setReady(true)
    }
  }, [faultEventLogs]);

  useEffect(() => {
    dispatch(getFaultEventLog(page, pageSize));
    
  }, [dispatch, page, pageSize]);

  const onDownloadClick = useCallback(
    async (from, to) => {
      setDownloading(true);
      const res = await getCriticalDeviceDataApiCall(
        0,
        totalCount,
        abortControllerRef.current.signal,
        from,
        to,
      );
      setDownloading(false);
      return res.data.map((d) => {
        delete d['GeoCoords'];
        return d;
      });
    },
    [totalCount],
  );

  function showLoader(requiredData=[]){
    for (let index = 0; index < requiredData.length; index++) {
      if(requiredData[index] === undefined){
        return true
      }
    }

    return !ready
  }



  return (
    <>
      { 
      showLoader([totalCount, faultEventLogs]) ? (<PropagateLoader className="Center" size={10} color="#000" speedMultiplier={0.7} />) :
      (
        <DataTable
          usePaper={true}
          displayColumns={faultEventLogColumns}
          tableData={faultEventLogs}
          buttonColumns={[]}
          buttonsData={{}}
          enableDownloadCSV={true} 
          csvFileName='fault_event_log.csv'
          onDownloadClick={onDownloadClick}
          page={page}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          totalCount={totalCount}
          overrideNotToDisplay={overrideNotToDisplay}
        />
      )
      }
    </>
  );
}
