import { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from '../Layout';
import { isAuthenticUser } from '../../lib/validateuser';
import FullLoader from '../Utils/FullLoader';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await isAuthenticUser();
      setIsAuthenticated(authStatus);
      setIsLoading(false);
    };
    checkAuth();
  }, [Component, ...Object.values(rest)]);

  if (isLoading) {
    return <FullLoader />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return (
            <Layout>
              <Component {...rest} {...props} />
            </Layout>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: '/auth',
              }}
            />
          );
        }
      }}
    />
  );
};
ProtectedRoute.propTypes = {
  component: PropTypes.func,
};
export default ProtectedRoute;
