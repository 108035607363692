import { combineReducers } from 'redux';
import { AdminUserReducer } from './adminActions';
import { UserReducer } from './user';
import { UiReducer } from './ui';
import { DeviceDataReducer } from './devices';

const rootReducer = combineReducers({
  user: UserReducer,
  admin: AdminUserReducer,
  ui: UiReducer,
  devices: DeviceDataReducer,
});

export default rootReducer;
