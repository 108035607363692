import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Nav, Navbar, NavDropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FiLogOut } from 'react-icons/fi';
import { PasswordChangeModal } from '../../Modal/PasswordChangeModal';
import { logoutApiCall } from '../../../services/users';

const Header = () => {
  const timezoneValue = useSelector((state) => state.user.user.timezoneName);
  const timezoneAbbr = useSelector((state) => state.user.user.Timezone);
  const userNameLong = useSelector((state) => state.user.user.UserNameLong);
  const contactEmail = useSelector((state) => state.user.user.ContactEmail);
  const userType = useSelector((state) => state.user.user.userType);

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const handleClosePasswordModal = () => setShowPasswordModal(false);
  const handleShowPasswordModal = () => setShowPasswordModal(true);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const userDropdownTitle = (
    <OverlayTrigger
      className="user-icon"
      key={'user-tooltip'}
      placement={'left'}
      overlay={
        <Tooltip>
          Account: {userNameLong} ({contactEmail})
        </Tooltip>
      }
    >
      <div className="initial-circle">{userNameLong ? userNameLong[0].toUpperCase() : ''}</div>
    </OverlayTrigger>
  );

  return (
    <>
      <Navbar expand="lg" variant="light" bg="light" sticky="top" className="border-bottom">
        <div className="time-zone">
          Timezone: {timezoneValue} - {timezoneAbbr}
        </div>
        <Navbar.Collapse className="collapse navbar-collapse" id="navbarSupportedContent">
          <Nav className="ml-auto mt-2 mt-lg-0">
            <NavDropdown className="nav-item active user-dropdown" title={userDropdownTitle}>
              <NavDropdown.Item>{userNameLong}</NavDropdown.Item>
              <NavDropdown.Item>{contactEmail}</NavDropdown.Item>
              {userType == 'local' ? (
                <NavDropdown.Item
                  onClick={(e) => {
                    handleShowPasswordModal();
                  }}
                >
                  Change Password
                </NavDropdown.Item>
              ) : (
                <></>
              )}
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={(e) => {
                  localStorage.removeItem('token');
                  logoutApiCall()
                    .then((resp) => {
                      if (userType == 'azure') {
                        window.location.href = `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`;
                      } else {
                        window.location.href = '/';
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      if (userType == 'azure') {
                        window.location.href = `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`;
                      } else {
                        window.location.href = '/';
                      }
                    });
                }}
              >
                Logout&nbsp;
                <FiLogOut />
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <PasswordChangeModal
        showPasswordModal={showPasswordModal}
        handleClosePasswordModal={handleClosePasswordModal}
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
      />
    </>
  );
};

export default Header;
