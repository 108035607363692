import API from './axios';
export const getCriticalDeviceDataApiCall = async (
  page = 0,
  limit = 30,
  signal,
  dateFrom,
  dateTo,
) => {
  return API.get('api/device-records/critical-data', {
    params:
      dateFrom && dateTo
        ? {
            from: dateFrom,
            to: dateTo,
          }
        : { page, limit },
    signal: signal,
  });
};

export const getMapDataApiCall = async () => {
  return API.get('api/device-records/map-data');
};

export const addBulkDevices = async (devices) => {
  return API.post('api/admin/bulk-devices', devices);
};
export const getDeviceDatalogApiCall = async (
  page = 0,
  limit = 30,
  signal,
  fetchTotalRows = false,
  dateFrom,
  dateTo,
) => {
  return API.get('api/device-records/data-log', {
    params: dateFrom && dateTo ? { from: dateFrom, to: dateTo } : { page, limit, fetchTotalRows },
    signal: signal,
  });
};
export const getDeviceDatalogByIdApiCall = async (id) => {
  return API.get(`api/device-records/device-information/data-log/${id}`);
};

export const getDeviceEventsByIdApiCall = async (id, page = 0, limit = 30, dateFrom, dateTo) => {
  return API.get(`api/device-records/device-information/events/${id}`, {
    params:
      dateFrom && dateTo
        ? {
            from: dateFrom,
            to: dateTo,
          }
        : { page, limit },
  });
};

export const getDeviceLoadHistoryByIdApiCall = async (id, page = 0, limit = 30, dateFrom, dateTo) => {
  return API.get(`api/device-records/device-information/load-history/${id}`, {
    params:
      dateFrom && dateTo
        ? {
            from: dateFrom,
            to: dateTo,
          }
        : { page, limit },
  });
};

export const getDevicesDataApiCall = async () => {
  return API.get('api/device-records/device-information');
};
