import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './style.css';
import { getUserContactsApiCall, updateUserContactsApiCall } from '../../services/users';
export default function AddEditUserContactList() {
  const [emailList, setEmailList] = useState([]);
  const [numberList, setNumberList] = useState([]);
  const [groupContacts, setGroupContacts] = useState(null);
  const [tempEmail, setTempEmail] = useState('');
  const [tempNumber, setTempNumber] = useState('');
  useEffect(() => {
    getUserContactsApiCall()
      .then((res) => {
        const { emailList, numberList } = res.data.userContacts;
        setGroupContacts(res.data.groupContacts);
        if (!emailList || !emailList.length) {
          setEmailList([]);
        } else {
          const arr = emailList.split(',');
          setEmailList(arr);
        }
        if (!numberList || !numberList.length) {
          setNumberList([]);
        } else {
          const arr = numberList.split(',');
          setNumberList(arr);
        }
      })
      .catch((err) => {
        setEmailList([]);
        setNumberList([]);
      });
  }, []);
  function handleUpdateData(event) {
    event.preventDefault();
    const emailStr = emailList.toString();
    const numStr = numberList.toString();
    const payload = {
      emailList: emailStr,
      numberList: numStr,
    };
    updateUserContactsApiCall(payload)
      .then((res) => {
        console.log('data updated');
      })
      .catch((err) => console.error('no id did not'));
  }
  function handleSubmitEmail(event) {
    event.preventDefault();
    const isValidEmail = ValidateEmail(tempEmail);
    if (isValidEmail) {
      let emailListCopy = [...emailList];
      emailListCopy.push(tempEmail);
      setEmailList(emailListCopy);
      setTempEmail('');
    }
  }
  function handleSubmitNumber(event) {
    event.preventDefault();
    const numberListCopy = [...numberList];
    numberListCopy.push(`+${tempNumber}`);
    setNumberList(numberListCopy);
    setTempNumber('');
  }
  function removeEmail(email) {
    let updatedEmailList = emailList.filter((mail) => mail !== email);
    setEmailList(updatedEmailList);
  }
  function removeNumber(num) {
    let updatedNumberList = numberList.filter((number) => number !== num);
    setNumberList(updatedNumberList);
  }
  function ValidateEmail(mail) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return true;
    }
    return false;
  }
  return (
    <div>
      <Button className="pull-right separate-me" onClick={handleUpdateData} variant="danger">
        Save Changes
      </Button>
      <br />
      <br />
      <Form onSubmit={handleSubmitEmail}>
        <Form.Group size="lg" controlId="user">
          <Form.Label>
            <b>Add Contact Email</b>
          </Form.Label>
          <Form.Control
            autoFocus
            value={tempEmail}
            type="text"
            onChange={(event) => setTempEmail(event.target.value)}
          />
          <Button
            block
            size="lg"
            type="submit"
            disabled={!tempEmail.length || emailList.length > 20}
          >
            Add
          </Button>
        </Form.Group>
      </Form>
      <ListGroup>
        {emailList.map((mail, index) => (
          <div key={index}>
            <ListGroup.Item key={index}>{mail}</ListGroup.Item>

            <Button
              className="pull-right"
              onClick={() => {
                removeEmail(mail);
              }}
            >
              Delete
            </Button>
          </div>
        ))}
      </ListGroup>

      <Form onSubmit={handleSubmitNumber}>
        <Form.Group size="lg" controlId="user">
          <Form.Label>
            <b>Add Contact Phone</b>
          </Form.Label>
          <PhoneInput
            country={'us'}
            value={tempNumber}
            onChange={(phone) => setTempNumber(phone)}
          />
          <Button
            block
            size="lg"
            type="submit"
            disabled={!tempNumber.length || numberList.length >= 20}
          >
            Add
          </Button>
        </Form.Group>
      </Form>
      <ListGroup>
        {numberList.map((number, index) => (
          <div key={index}>
            <ListGroup.Item key={index}>{number}</ListGroup.Item>

            <Button
              className="pull-right"
              onClick={() => {
                removeNumber(number);
              }}
            >
              Delete
            </Button>
          </div>
        ))}
      </ListGroup>
      {groupContacts && (
        <div>
          <h3 style={{ marginBottom: '30px' }}>Contacts from your groups</h3>
          <p>
            Emails: <strong>{groupContacts.emails.split(',').join(', ')}</strong>
          </p>
          <p>
            Phone numbers: <strong>{groupContacts.phoneNumbers.split(',').join(', ')}</strong>
          </p>
        </div>
      )}
    </div>
  );
}
