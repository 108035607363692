import { SET_ADMIN_GROUPS, SET_ADMIN_USERS, SET_ADMIN_DEVICES } from '../constants/action-types';
const initalUserState = {
  users: [],
  groups: [],
  devices: [],
  error: null,
};

const reducer = (state = initalUserState, action) => {
  switch (action.type) {
    case SET_ADMIN_USERS: {
      return { ...state, users: action.payload };
    }
    case SET_ADMIN_GROUPS: {
      return { ...state, groups: action.payload };
    }
    case SET_ADMIN_DEVICES: {
      return { ...state, devices: action.payload };
    }
    default: {
      return state;
    }
  }
};
export { reducer as AdminUserReducer };
