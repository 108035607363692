import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { HashLoader, BeatLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import logo from './logo.png';
import './style.css';
import { loginAction } from '../../actions/login';

import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { decodeJwtToken } from '../../lib/validateuser';
import { SET_USER } from '../../constants/action-types';
import history from '../../lib/history';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingAzure, setLoadingAzure] = useState(false);

  const { instance, inProgress } = useMsal();
  const dispatch = useDispatch();
  function validateForm() {
    return email.length > 0 && password.length > 0;
  }
  const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const credentials = {
      email,
      password,
    };
    dispatch(loginAction(credentials, setLoading));
  }

  const handleAzureLogin = () => {
    instance
      .loginPopup({
        scopes: ['email', 'openid'],
      })
      .then((response) => {
        setLoadingAzure(true);
        axios
          .create({
            baseURL: API_ADDRESS,
            headers: { Authorization: `Bearer ${response.idToken}` },
          })
          .post('api/auth/validate-azure', {})
          .then((azureResponse) => {
            const { accessToken } = azureResponse.data;
            if (accessToken) {
              localStorage.setItem('token', accessToken);
              const userData = decodeJwtToken(accessToken);
              dispatch({ type: SET_USER, payload: userData });
              history.push('/');
            } else {
              toast.warning('Entra authentication cancelled.');
            }
            setLoadingAzure(false);
          })
          .catch((error) => {
            console.log(error);
            if (error.response?.data?.message) {
              toast.warning(error.response?.data?.message);
            } else {
              toast.warning('Cannot login with Entra. Please contact the administrator.');
            }
            setLoadingAzure(false);
          });
      })
      .catch((error) => {
        console.log(error);
        if (error.message.includes('user_cancelled')) {
          toast.warning('Entra authentication cancelled.');
        } else {
          toast.warning('Cannot login with Entra. Please contact the administrator.');
        }
      });
  };

  return (
    <div className="limiter">
      <ToastContainer position="top-right" />
      <div className="container-login100">
        <div className="wrap-login100">
          <div>
            <img src={logo} alt="Logo" className="Center Logo" />
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              className="full-width mt-4"
              onClick={handleAzureLogin}
              disabled={inProgress === 'login' || loadingAzure}
            >
              {inProgress === 'login' ? (
                'Login is currently in progress'
              ) : loadingAzure ? (
                <>
                  Verifying your credentials&nbsp;
                  <BeatLoader size={10} color="#1b6bae" speedMultiplier={0.8} />
                </>
              ) : (
                'Login with Entra ID'
              )}
            </Button>
            <div className="divider">
              <span className="divider-text">or</span>
            </div>
            <div className="login-fields-container">
              <TextField
                id="outlined-email-input"
                size="small"
                label="Email Address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="login-fields"
              />
              <TextField
                id="outlined-password-input"
                size="small"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-fields"
              />
            </div>
            <Button
              size="medium"
              type="submit"
              variant="contained"
              className="full-width"
              disabled={!validateForm() || loading}
              onClick={handleSubmit}
            >
              {loading && <HashLoader size={30} color="#1b6bae" speedMultiplier={0.8} />}
              {!loading && 'Login'}
            </Button>
            <div className="mt-2 auth-nav-link">
              <Link to="/forgot-password">Forgot password?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
