import Routes from './routes';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-datetime/css/react-datetime.css';

function App() {
  return <Routes />;
}

export default App;
