import jwt_decode from 'jwt-decode';
import { validateJwtApiCall } from '../services/users';
export const decodeJwtToken = (token) => {
  if (token) {
    const decoded = jwt_decode(token);
    return decoded;
  }
  return null;
};
export const returnUserFromLocalStorage = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = decodeJwtToken(token);
    if (decodedToken) {
      return decodedToken;
    }
  }
  return null;
};
export const isAuthenticUser = async () => {
  const token = localStorage.getItem('token');
  const decodedToken = decodeJwtToken(token);

  if (!decodedToken) {
    return false;
  }

  try {
    const apiRes = await validateJwtApiCall();
    if (apiRes.data.valid) {
      const { exp } = decodedToken;
      const now = new Date();
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);

      if (secondsSinceEpoch < exp) {
        return true;
      } else {
        localStorage.removeItem('token');
        return false;
      }
    }
    return false;
  } catch (error) {
    localStorage.removeItem('token');
    return false;
  }
};
