/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './style.css';
import { addGroupApiCall } from '../../../services/users';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminUsers } from '../../../actions/adminActions';
import { toggleAdminSubMenu } from '../../../actions/ui';

const initialFields = {
  name: '',
  users: [''],
};
export default function AddGroup() {
  const adminUsers = useSelector((state) => state.admin.users) || [];
  const showAdminSubMenu = useSelector((state) => state.ui.showAdminSubMenu);
  const [fields, setFields] = useState(initialFields);
  const [loading, setLoading] = useState(false);
  const [addGroupSuccess, setAddGroupSuccess] = useState(false);
  const [addGroupFailure, setAddGroupFailure] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminUsers());
    if (!showAdminSubMenu) {
      dispatch(toggleAdminSubMenu());
    }
  }, []);
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const formData = {
      ...fields,
      users: fields.users[0] ? fields.users : [],
    };
    const groupData = {
      GroupName: formData.name,
      GroupUsers: formData.users,
    };
    addGroupApiCall(groupData)
      .then((res) => {
        setLoading(false);
        setAddGroupSuccess(true);
        setFields(initialFields);
        setTimeout(() => {
          setAddGroupSuccess(false);
        }, 5000);
      })
      .catch((err) => {
        setAddGroupFailure(true);
        setTimeout(() => {
          setAddGroupFailure(false);
        }, 5000);
      });
  }

  return (
    <div className="Add-User">
      <Form onSubmit={handleSubmit}>
        <h2 className="h2 Center Margin">Add Group Form</h2>
        {addGroupSuccess && <p style={{ backgroundColor: 'lightgreen' }}>Group Added</p>}
        {addGroupFailure && <p style={{ backgroundColor: 'red' }}>Some Error Occurred</p>}
        <Form.Group size="lg" controlId="group">
          <Form.Label>Group Name</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={fields.name}
            onChange={(e) =>
              setFields({
                ...fields,
                name: e.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Label>Users</Form.Label>
        {fields.users.map((field, index) => (
          <Form.Control
            className="mb-2"
            key={'user-' + index}
            as="select"
            value={fields.users[index]}
            onChange={(e) => {
              const users = [...fields.users];
              if (!e.target.value && users.length > 1) {
                users.splice(index, 1);
              } else {
                users[index] = e.target.value;
              }
              setFields({
                ...fields,
                users,
              });
            }}
          >
            <option value="">Select an Option</option>
            {adminUsers.map((adminUser, index) => (
              <option
                key={'option-' + index}
                value={adminUser.UserID}
                disabled={!!fields.users.includes(adminUser.UserID)}
              >
                {adminUser.UserNameLong}
              </option>
            ))}
          </Form.Control>
        ))}
        <button
          type="button"
          className="btn btn-sm btn-link"
          onClick={() =>
            setFields({
              ...fields,
              users: [...fields.users, ''],
            })
          }
          disabled={!fields.users[fields.users.length - 1]}
        >
          + Add user
        </button>
        <Button className="mt-4" block size="lg" type="submit" disabled={!fields.name.length}>
          {loading && 'Adding Group'}
          {!loading && 'Submit'}
        </Button>
      </Form>
    </div>
  );
}
