/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BeatLoader } from 'react-spinners';
export function DeleteModal({
  name,
  onClickYes,
  onClickNo,
  show,
  deleteSuccess,
  deleteFailure,
  id,
  loading,
}) {
  return (
    <Modal show={show} onHide={onClickNo}>
      <Modal.Header closeButton>
        <Modal.Title>Delete {name}</Modal.Title>
      </Modal.Header>

      {deleteSuccess && (
        <Modal.Body>
          <p style={{ backgroundColor: 'lightgreen' }}>{name} Deleted</p>
        </Modal.Body>
      )}
      {deleteFailure && (
        <Modal.Body>
          <p style={{ backgroundColor: 'red' }}>Some Error Occured</p>
        </Modal.Body>
      )}

      <Modal.Footer className="modal-footer">
        <Button
          variant="secondary"
          size="sm"
          onClick={onClickNo}
          className="float-left"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          size="sm"
          onClick={() => onClickYes(id)}
          className="float-right"
          disabled={loading}
        >
          {loading && <BeatLoader size={10} color="#fff" speedMultiplier={0.7} />}
          {!loading && 'Delete'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
