import React, { useEffect, useState } from 'react';
import './style.css';
import { Row, Col, Tabs, Tab, Button, OverlayTrigger, Popover, Form, Alert } from 'react-bootstrap';
import { addUserApiCall, getTimeZonesApiCall, addBulkUsers } from '../../../services/users';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAdminSubMenu } from '../../../actions/ui';
import moment from 'moment-timezone';

import { parse } from 'papaparse';

export default function Adduser() {
  const [SelectedFile, setSelectedFile] = useState(null);
  const [UserNameLong, setUserNameLong] = useState('');
  const [UserNameShort, setUserNameShort] = useState('');
  const [ContactEmail, setContactEmail] = useState('');
  const [ContactPhone, setContactPhone] = useState('');
  const [AccessLevel, setAccessLevel] = useState('User');
  const [Timezone, setTimeZone] = useState(moment.tz.guess());
  const [Password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [addUserSuccess, setAddUserSuccess] = useState(false);
  const [timezones, setTimeZones] = useState([]);
  const [toggleFilter, setToggleHoursFilter] = useState(false);
  const [hours, setHours] = useState('');
  const [addUserFailure, setAddUserFailure] = useState(0);
  const [csvUsersAdded, setCsvUsersAdded] = useState(false);
  const [csvUsersError, setCsvUsersError] = useState(false);
  const showAdminSubMenu = useSelector((state) => state.ui.showAdminSubMenu);
  useEffect(() => {
    setTimeZones(moment.tz.names());
  }, []);
  function validateForm() {
    return true;
    // return email.length > 0 && password.length > 0;
  }
  const dispatch = useDispatch();
  useEffect(() => {
    if (!showAdminSubMenu) {
      dispatch(toggleAdminSubMenu());
    }
  }, [dispatch, showAdminSubMenu]);
  const handleFileSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    try {
      parse(SelectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: async (results, file) => {
          try {
            await addBulkUsers({
              users: results.data,
            });
            setCsvUsersAdded(true);
            setCsvUsersError(false);
            setSelectedFile(null);
          } catch (e) {
            setCsvUsersAdded(false);
            setCsvUsersError(true);
          } finally {
            setLoading(false);
          }
        },
      });
    } catch (e) {
      setCsvUsersAdded(false);
      setCsvUsersError(true);
      setLoading(false);
    }
  };

  function handleSubmit(event) {
    setLoading(true);
    const userObj = {
      ...(UserNameLong.length && { UserNameLong }),
      ...(UserNameShort.length && { UserNameShort }),
      ...(ContactEmail.length && { ContactEmail }),
      ...(ContactPhone.length && { ContactPhone }),
      ...(Password.length && { Password }),
      ...(Timezone.length && { Timezone }),
    };
    if (toggleFilter) {
      userObj.toggleFilter = 1;
      userObj.hours = hours;
    } else {
      userObj.toggleFilter = 0;
    }
    let accessKey = 3;
    if (AccessLevel === 'Admin') {
      accessKey = 1;
    } else if (AccessLevel === 'User Admin') {
      accessKey = 2;
    }
    userObj.AccessLevel = accessKey;
    event.preventDefault();
    addUserApiCall(userObj)
      .then((res) => {
        setLoading(false);
        setAddUserSuccess(true);
        setTimeout(() => {
          setAddUserSuccess(false);
        }, 5000);
      })
      .catch((error) => {
        setLoading(false);
        setAddUserFailure(error.toString().includes('409') ? 409 : 500);
        setTimeout(() => {
          setAddUserFailure(0);
        }, 5000);
      });
  }

  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: 450 }}>
      <Popover.Title as="h3">CSV Format</Popover.Title>
      <Popover.Content>
        <b>
          UserNameLong,UserNameShort,ContactEmail,Password,ContactPhone,AccessLevel,Timezone,toggleFilter,hours
        </b>
        <br />
        name1,short1,test@email.com,12345,5555555,1,EST,1,5
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="Add-User">
      <h2 className="h2 Margin mt-0 mb-3">Add Users</h2>
      <Tabs defaultActiveKey="form" className="mb-3">
        <Tab eventKey="form" title="User Form">
          <Form onSubmit={handleSubmit}>
            <Form.Group size="lg" controlId="user">
              <Form.Label>UserNameLong</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                value={UserNameLong}
                onChange={(e) => setUserNameLong(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="user">
              <Form.Label>UserNameShort</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                value={UserNameShort}
                onChange={(e) => setUserNameShort(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="email">
              <Form.Label>ContactEmail</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                value={ContactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="number"
                value={ContactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>AccessLevel</Form.Label>
              <Form.Control
                as="select"
                value={AccessLevel}
                onChange={(e) => setAccessLevel(e.target.value)}
              >
                <option>Admin</option>
                <option>User Admin</option>
                <option>User</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Timezone</Form.Label>
              <Form.Control
                as="select"
                value={Timezone}
                onChange={(e) => setTimeZone(e.target.value)}
              >
                {timezones.map((zone) => (
                  <option key={zone}>{zone}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Enable Filter</Form.Label>
              <Form.Check
                type="switch"
                id="custom-switch-1"
                checked={toggleFilter}
                onClick={() => setToggleHoursFilter(!toggleFilter)}
              />
            </Form.Group>
            {toggleFilter && (
              <Form.Group size="lg" controlId="hours">
                <Form.Label>Hours</Form.Label>
                <Form.Control
                  type="number"
                  value={hours}
                  onChange={(e) => setHours(e.target.value)}
                />
              </Form.Group>
            )}
            {addUserSuccess && (
              <p style={{ backgroundColor: 'lightgreen', padding: '10px' }}>User Added</p>
            )}
            {addUserFailure ? (
              <p style={{ backgroundColor: 'red', color: '#fff', padding: '10px' }}>
                {addUserFailure === 409 ? <>Email must be unique</> : <>Some Error Occurred</>}
              </p>
            ) : (
              <></>
            )}
            <Button block size="lg" type="submit" disabled={!validateForm()}>
              {loading && 'Adding User'}
              {!loading && 'Submit'}
            </Button>
          </Form>
        </Tab>
        <Tab eventKey="upload" title="Upload CSV File">
          <Form onSubmit={handleFileSubmit}>
            {csvUsersAdded ? (
              <Alert variant="success" onClose={() => setCsvUsersAdded(false)} dismissible>
                Users added
              </Alert>
            ) : null}
            {csvUsersError ? (
              <Alert variant="danger" onClose={() => setCsvUsersError(false)} dismissible>
                There was an error adding the users. Verify the CSV file and try again.
              </Alert>
            ) : null}

            <Row>
              <Col>
                <Form.Control
                  type="file"
                  id="custom-file"
                  custom
                  label={SelectedFile ? SelectedFile.name : 'Select a CSV file'}
                  accept=".csv"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                  }}
                />
              </Col>
              <Col xs={2}>
                <OverlayTrigger
                  trigger={['click', 'hover', 'focus']}
                  delay={300000}
                  placement="bottom"
                  overlay={popover}
                >
                  <Button variant="info">Info</Button>
                </OverlayTrigger>
              </Col>
            </Row>

            <Button block size="lg" type="submit" className="mt-2" disabled={!SelectedFile}>
              {loading && 'Adding Users'}
              {!loading && 'Add Users'}
            </Button>
          </Form>
        </Tab>
      </Tabs>
    </div>
  );
}
