/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';

import './style.css';
import { getAdminGroups } from '../../../actions/adminActions';
import { toggleAdminSubMenu } from '../../../actions/ui';
import { addMultispeakProviderApiCall } from '../../../services/multispeak';

export default function AddMultiSpeakProvider() {
  const adminGroups = [...(useSelector((state) => state.admin.groups) || [])];
  const showAdminSubMenu = useSelector((state) => state.ui.showAdminSubMenu);

  const notificationMethodSelectRef = useRef();
  const groupAssociationsSelectRef = useRef();

  const [providerName, setProviderName] = useState('');
  const [providerURL, setProviderURL] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [faultNotificationMethod, setFaultNotificationMethod] = useState('');
  const [groupAssociations, setGroupAssociations] = useState([]);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminGroups());
    if (!showAdminSubMenu) {
      dispatch(toggleAdminSubMenu());
    }
  }, []);

  function clearForm(event) {
    setProviderName('');
    setProviderURL('');
    setUserName('');
    setPassword('');
    setFaultNotificationMethod('');
    setGroupAssociations([]);
    event.target.reset();

    notificationMethodSelectRef.current.clearValue();
    groupAssociationsSelectRef.current.clearValue();
  }

  function isValidUrl(url) {
    const urlRegex = /^(?:(?:https?):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
    return urlRegex.test(url);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!isValidUrl(providerURL)) {
      toast.warn('Please enter a valid endpoint URL');
      setLoading(false);
      return;
    }

    if (!faultNotificationMethod) {
      toast.warn('Please select the Fault Notification method');
      setLoading(false);
      return;
    }

    const msProviderData = {
      Name: providerName,
      EndpointURL: providerURL,
      AccessUserName: userName,
      AccessPassword: password,
      FaultNotificationMethod: faultNotificationMethod,
      GroupAssociations: groupAssociations.join(),
    };

    addMultispeakProviderApiCall(msProviderData)
      .then((res) => {
        if (res.data.success) {
          toast.success('MultiSpeak Provider Added');
        } else {
          toast.error('Error occured');
        }
        setLoading(false);
        clearForm(event);
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Error occured');
      });
  }

  return (
    <div className="Add-User">
      <Form onSubmit={handleSubmit}>
        <h2 className="h2 Center Margin">Add MultiSpeak Provider</h2>
        <hr className="mb-4" />
        <Form.Group size="lg" controlId="group">
          <Form.Label>Provider Name</Form.Label>
          <Form.Control autoFocus type="text" onChange={(e) => setProviderName(e.target.value)} />
        </Form.Group>
        <Form.Group size="lg" controlId="group">
          <Form.Label>Provider Endpoint URL</Form.Label>
          <Form.Control type="text" onChange={(e) => setProviderURL(e.target.value)} />
        </Form.Group>
        <Form.Group size="lg" controlId="group">
          <Form.Label>Access Username</Form.Label>
          <Form.Control type="text" onChange={(e) => setUserName(e.target.value)} />
        </Form.Group>
        <Form.Group size="lg" controlId="group">
          <Form.Label>Access Password</Form.Label>
          <Form.Control type="text" onChange={(e) => setPassword(e.target.value)} />
        </Form.Group>
        <Form.Group controlId="msProviderForm.faultNotificationMethod">
          <Form.Label>Fault Notification Method</Form.Label>
          <Select
            ref={notificationMethodSelectRef}
            onChange={(option) => {
              if (option === null) {
                setFaultNotificationMethod('');
              } else {
                setFaultNotificationMethod(option.value);
              }
            }}
            options={[
              {
                value: 'AssessmentLocationChangedNotification',
                label: 'AssessmentLocationChangedNotification',
              },
              {
                value: 'ODEventNotification',
                label: 'ODEventNotification',
              },
              {
                value: 'SCADAStatusChangedNotification',
                label: 'SCADAStatusChangedNotification',
              },
            ]}
          />
        </Form.Group>
        <Form.Group controlId="msProviderForm.groupAssociations">
          <Form.Label>Group Associations</Form.Label>
          <Select
            multiple
            ref={groupAssociationsSelectRef}
            onChange={(selectedOptions) => {
              if (selectedOptions === null) {
                setGroupAssociations([]);
              } else {
                setGroupAssociations(selectedOptions.map((option) => option.value));
              }
            }}
            options={adminGroups.map((adminGroup) => ({
              value: adminGroup.GroupID,
              label: adminGroup.GroupName,
            }))}
            isMulti={true}
          />
        </Form.Group>

        <Button className="mt-4" size="lg" type="submit" disabled={!providerName.length || loading}>
          {loading && <BeatLoader size={10} color="#fff" speedMultiplier={0.7} />}
          {!loading && 'Submit'}
        </Button>
      </Form>
    </div>
  );
}
