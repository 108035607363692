import {
  SET_CRITICAL_DEVICEDATA,
  SET_DATALOG_DEVICEDATA,
  SET_DEVICES,
  SET_DATALOG_DEVICEDATA_BY_ID,
  SET_MAP_DATA,
  SET_CRITICAL_DEVICEDATA_COLUMNS,
  SET_DATALOG_DEVICEDATA_COLUMNS,
  SET_DATALOG_DEVICEDATA_COUNT,
  SET_MAP_DATA_LOADER,
  SET_EVENTLOG_DEVICEDATA_BY_IMEI,
  SET_DEVICE_LOAD_HISTORY,
} from '../constants/action-types';
import { convertColumnToRealName } from '../lib/convertColumnsToRealLanguage';
import {
  getCriticalDeviceDataApiCall,
  getDeviceDatalogApiCall,
  getDeviceDatalogByIdApiCall,
  getDevicesDataApiCall,
  getMapDataApiCall,
  getDeviceEventsByIdApiCall,
  getDeviceLoadHistoryByIdApiCall
} from '../services/devices';

import { addBatteryStatuses, convertToRealDM1FaultStatuses } from './helpers';

const hiddenFields = ['TotalCount', 'GeoCoords'];

export const getCriticalDataCol = (signal) => {
  return async (dispatch) => {
    try {
      const page = 0;
      const limit = 1;
      const apiRes = await getCriticalDeviceDataApiCall(page, limit, signal);
      const criticalDevicesData = apiRes.data;
      const tempColumns = [];
      const objectKeys = Object.keys(criticalDevicesData[0]);
      objectKeys.map((colKey) => {
        let columnRealName = convertColumnToRealName(colKey);
        let columnSize = columnRealName.length * 10;
        if (
          columnRealName.toLowerCase().includes('time') &&
          !columnRealName.toLowerCase().includes('timer')
        ) {
          columnSize = 270;
        }
        if (columnRealName.toLowerCase().includes('imei')) {
          columnSize = 170;
        }
        if (columnRealName.toLowerCase().includes('name')) {
          columnSize = 170;
        }
        tempColumns.push({
          headerName: columnRealName,
          field: colKey,
          width: columnSize < 150 ? 150 : columnSize,
        });
      }) 
      dispatch({ type: SET_CRITICAL_DEVICEDATA_COLUMNS, payload: tempColumns });
    } catch (error) {
      // handle when auth failed.
    }
  };
};

export const getDataLogCol = (signal, fetchTotalRows=false, page=0, limit=1) => {
  return async (dispatch) => {
    try {
      const apiRes = await getDeviceDatalogApiCall(page, limit, signal, fetchTotalRows);
      const criticalDevicesData = apiRes.data;
      const tempColumns = [];
      const objectKeys = Object.keys(criticalDevicesData[0]);
      objectKeys &&
        objectKeys.map((key) => {
          if (!hiddenFields.includes(key)) {
            let columnRealName = convertColumnToRealName(key);
            let columnSize = columnRealName.length * 10;
            if (
              columnRealName.toLowerCase().includes('time') &&
              !columnRealName.toLowerCase().includes('timer')
            ) {
              columnSize = 270;
            }
            if (columnRealName.toLowerCase().includes('imei')) {
              columnSize = 170;
            }
            tempColumns.push({
              headerName: columnRealName,
              field: key,
              width: columnSize < 150 ? 150 : columnSize,
            });
          }
        });
      if (fetchTotalRows) {
        dispatch({
          type: SET_DATALOG_DEVICEDATA_COUNT,
          payload: parseInt(criticalDevicesData[0]['TotalCount']),
        });
      }
      const payload = addBatteryStatuses(criticalDevicesData)
      dispatch({type: SET_DATALOG_DEVICEDATA, payload: payload})
      dispatch({ type: SET_DATALOG_DEVICEDATA_COLUMNS, payload: tempColumns });
    } catch (error) {
      // handle when auth failed.
    }
  };
};

export const getMapData = () => {
  return async (dispatch) => {
    try {
      const apiRes = await getMapDataApiCall();
      dispatch({ type: SET_MAP_DATA, payload: apiRes.data });
      dispatch({ type: SET_MAP_DATA_LOADER, payload: true });
    } catch (error) {
      // handle when getMapData failed.
      dispatch({ type: SET_MAP_DATA_LOADER, payload: true });
    }
  };
};

export const getDatalogById = (id) => {
  return async (dispatch) => {
    try {
      const apiRes = await getDeviceDatalogByIdApiCall(id);

      const { data } = apiRes;

      const payload = addBatteryStatuses(data)

      dispatch({ type: SET_DATALOG_DEVICEDATA_BY_ID, payload: payload });
    } catch (error) {
      // handle when auth failed.
    }
  };
};

export const getDevices = () => {
  return async (dispatch) => {
    try {
      const apiRes = await getDevicesDataApiCall();

      const { data } = apiRes;

      const payload = addBatteryStatuses(data)

      dispatch({ type: SET_DEVICES, payload: payload });
    } catch (error) {
      // handle when auth failed.
    }
  };
};

export const getFaultEventLog = (page,pageSize) => {
  return async (dispatch) => {
    try {
      const apiRes = await getCriticalDeviceDataApiCall(page, pageSize);
      dispatch({ type: SET_CRITICAL_DEVICEDATA, payload: apiRes.data });
    } catch (error) {
      // handle when auth failed.
    }
  };
};

export const getEventLogByIMEI = (imeiNumber, page,pageSize) => {
  return async (dispatch) => {
    try {
      const apiRes = await getDeviceEventsByIdApiCall(imeiNumber, page, pageSize)

      const [totalCount, data ] = apiRes.data

      const convertedEventLogs =  convertToRealDM1FaultStatuses(data)
      const payload = {
        totalCount: totalCount,
        eventLogs: convertedEventLogs
      }
      dispatch({ type: SET_EVENTLOG_DEVICEDATA_BY_IMEI, payload: payload });
    } catch (error) {
      // handle when auth failed.
    }
  };
};

export const getDeviceLoadHistory = (imeiNumber, page, pageSize) => {
  return async (dispatch) => {
    try {
      const {data : [totalCount, data ]} = await getDeviceLoadHistoryByIdApiCall(imeiNumber, page, pageSize);

      const payload = {
        totalCount,
        loadHistory: data
      }

      dispatch({type: SET_DEVICE_LOAD_HISTORY, payload: payload})
      
    } catch (error) {
      console.error(`Error on fetching deviceLoadHistory for IMEI: ${imeiNumber}. Error : ${error}`)
    }
  }
}
