function convertBatteryVoltageToStatus(batteryType, voltage){
  const BATTERY_STATUS = {
    GOOD: "GOOD", FAIR: "FAIR", MARGINAL: "MARGINAL", POOR: "POOR"
  }

  if (batteryType === "BatteryMCU" && 3300 <= voltage && voltage < 3700){
    return  BATTERY_STATUS.GOOD
  } else if (batteryType === "BatteryLED" && 3100 <= voltage && voltage < 3700){
    return  BATTERY_STATUS.GOOD
  } else if (batteryType === "BatteryLED" && 2950 <= voltage && voltage < 3100){
    return  BATTERY_STATUS.FAIR
  } else if (batteryType === "BatteryMCU" && 3050 <= voltage && voltage < 3300){
    return  BATTERY_STATUS.FAIR
  } else if (batteryType === "BatteryLED" && 2750 <= voltage && voltage < 2950){
    return  BATTERY_STATUS.MARGINAL
  } else if (batteryType === "BatteryMCU" && 2850 <= voltage && voltage < 3050){
    return  BATTERY_STATUS.MARGINAL
  }
  return "POOR" // Ranges below or Above limits
}

function addBatteryStatuses(data){
  const modifiedData = new Array(data.length)
  
  for (let index = 0; index < data.length; index++) {
    const rec = data[index]

    modifiedData[index] = {
      BatteryMCUStatus: convertBatteryVoltageToStatus("BatteryMCU", data[index].BatteryMCU),
      BatteryLEDStatus: convertBatteryVoltageToStatus("BatteryLED", rec.BatteryLED),
      ...rec
    }};
  return modifiedData
}

function convertToRealDM1FaultStatuses(data){

  const FAULT_STATUS = {
    FAULT_NONE: "NONE",
    FAULT_VERIFY: "PENDING",
    FAULT_INITIAL: "FAULT",
    FAULT_FINISHED: "FAULT CLEARED",
    FAULT_INPROGRESS: "FAULT IN PROGRESS "

  }
  const modifiedData = new Array(data.length)
  for (let index = 0; index < data.length; index++) {
    const rec = data[index];
    modifiedData[index] = {
      PhaseAEventStatus: FAULT_STATUS[rec["PhaseAEvent"]],
      PhaseBEventStatus: FAULT_STATUS[rec["PhaseBEvent"]],
      PhaseCEventStatus: FAULT_STATUS[rec["PhaseCEvent"]],
      EventTypeStatus: FAULT_STATUS[rec["EventType"]],
      ...rec
    }
    
  }
  
  return modifiedData
}

export {
  convertBatteryVoltageToStatus,
  addBatteryStatuses,
  convertToRealDM1FaultStatuses
}