/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
export function CustomMarker(props) {
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  return (
    <Marker
      position={props.position}
      icon={props.icon}
      zIndex={props.zIndex}
      onMouseOver={() => setShowInfoWindow(true)}
      onMouseOut={() => setShowInfoWindow(false)}
      title={props.Real_Name}
    >
      {showInfoWindow && (
        <InfoWindow>
          <>
            <p>
              <strong>{props.Real_Name}</strong>
            </p>
            {props.position && props.position.lat && props.position.lng ? (
              <p>
                Latitude: <strong>{props.position.lat}</strong>
                <br />
                Longitude: <strong>{props.position.lng}</strong>
              </p>
            ) : (
              <></>
            )}
          </>
        </InfoWindow>
      )}
    </Marker>
  );
}
