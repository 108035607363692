const realNames = {
  Real_Name: 'Name of FCI',
  IMEINumber: 'IMEI Number',
  date: 'Timestamp',
  PacketTimestamp: 'Packet Timestamp',
  EventTimestamp: 'Event Timestamp',
  Ongoing_fault: 'Fault Status',
  LEDDuration: 'Timer Length',
  LEDTimer: 'Current Timer Status',
  RecordID: 'Record ID',
  IndexID: 'Device Index',
  Group_Association: 'Device Group',
  rssi: 'Signal Strength/RSSI',
  FCIUniqueSerialNumber: 'Serial Number',
  current_status: 'Current Status',
  PhaseAEventStatus: 'Port 1',
  PhaseBEventStatus: 'Port 2',
  PhaseCEventStatus: 'Port 3',
  LastCheckInTime: 'Last CheckIn Time',
  ShipmentDate: 'Shipped On',
  EventTypeStatus: 'Event Type',
  PrefaultCurrent: 'Prefault Current',
  FaultCurrent: 'Fault Current',
  RecoverCurrent: 'Reset Current',
  PermanentFaultResetCurrentThreshold: 'PFault Reset Current Threshold',
  BatteryMCUStatus: 'MCU Battery',
  BatteryLEDStatus: 'Cellular Battery',
  PreviousAverageCurrent1m: "Avg Load Last 1m",
  PreviousAverageCurrent1h: "Avg Load Last 1h",
  PreviousPeakCurrent24h: "Peak Load Last 24h",
  Lat: "Registered Lat",
  Long: "Registered Long",
  gps_lat: "Last Reported Lat", 
  gps_long: "Last Reported Long",
  model: "Model",
  Event_Record_ID: "Event Record ID"
};

export const convertColumnToRealName = (columnName) => {
  if (realNames[columnName]) {
    return realNames[columnName];
  }
  else if (columnName.includes('AverageCurrentH')){
    return columnName.split('AverageCurrent')[1];
  }
  else return columnName;
};
