/* eslint-disable react/prop-types */
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AddGroup from './components/Admin/AddGroup';
import Adduser from './components/Admin/AddUser';
import AddDevice from './components/Admin/AddDevice';
import AdminProtectedRoute from './components/AdminProtectedRoutes';
import Login from './components/Auth/Login';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import history from './lib/history';
import Dashboard from './pages/Dashboard';
import EditUsers from './components/Admin/EditUsers';
import EditGroups from './components/Admin/EditGroup';
import EditDevice from './components/Admin/EditDevice';
import ViewCriticalDeviceData from './components/CriticalEventLog';
import ViewDevicesDataLog from './components/DataLog';
import { ViewEditDevices } from './components/Devices';
import ViewDevicesDataLogById from './components/DataLogById';
import UserAndAdminProtectedRoute from './components/UserAndSuperAdminProtectedRoute';
import AddEditUserContactList from './components/addContactList';
import UpdateMessageTemplate from './components/Admin/AddMessageTemplate';
import AddMultiSpeakProvider from './components/Admin/AddMultiSpeakProvider';
import EditMultiSpeakProvider from './components/Admin/EditMultiSpeakProvider';
import Organizations from './components/Admin/Organizations';
import ViewDeviceEventsById from './components/DeviceEventsById';
import ViewDeviceLoadHistoryById from './components/DeviceLoadHistoryById';
const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <ProtectedRoute exact path="/" component={Dashboard} />
        <ProtectedRoute exact path="/critical-data" component={ViewCriticalDeviceData} />
        <ProtectedRoute exact path="/data-log" component={ViewDevicesDataLog} />
        <ProtectedRoute exact path="/devices-data-log" component={ViewDevicesDataLogById} />
        <ProtectedRoute exact path="/device-events" component={ViewDeviceEventsById} />
        <ProtectedRoute exact path="/device-load-history" component={ViewDeviceLoadHistoryById} />
        <ProtectedRoute exact path="/devices" component={ViewEditDevices} />
        <UserAndAdminProtectedRoute
          exact
          path="/add-email-telephone"
          component={AddEditUserContactList}
        />
        <AdminProtectedRoute exact path="/admin/add-user" component={Adduser} />
        <AdminProtectedRoute
          exact
          path="/admin/add-message-template"
          component={UpdateMessageTemplate}
        />
        <AdminProtectedRoute exact path="/admin/add-group" component={AddGroup} />
        <AdminProtectedRoute exact path="/admin/add-device" component={AddDevice} />
        <AdminProtectedRoute exact path="/admin/view-edit-users" component={EditUsers} />
        <AdminProtectedRoute exact path="/admin/view-edit-groups" component={EditGroups} />
        <UserAndAdminProtectedRoute exact path="/admin/view-edit-devices" component={EditDevice} />
        <AdminProtectedRoute
          exact
          path="/admin/add-ms-provider"
          component={AddMultiSpeakProvider}
        />
        <AdminProtectedRoute
          exact
          path="/admin/view-edit-ms-provider"
          component={EditMultiSpeakProvider}
        />
        <AdminProtectedRoute exact path="/admin/organizations" component={Organizations} />
        <Route exact path="/auth" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password/:token" component={ResetPassword} />
      </Switch>
    </Router>
  );
};
export default Routes;
